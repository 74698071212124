.payment-radio,
.carrier-radio {
    display: flex;
    align-items: center;
}

.payment-name,
.carrier-name {
    margin-left: 16px;
    position: relative; 
    top: -10px;
}