.main-logo {
    height: 45px;
    display: block;
    cursor: pointer;
}

.mini-main-logo {
    display: none;
    cursor: pointer;
}

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px calc((100% - 1100px)/2);
    background-color: var(--theme-background-color);
    font-size: 12px;
    color: #555;
}

.select-group {
    display: flex;
    gap: 15px;
}

.icon-group {
    display: flex;
    align-items: center;
}

.cart-icon-container {
    position: relative;
    cursor: pointer;
    margin-right: 20px;
}

.login-icon-container {
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    font-size: 21px;
    color: #ffffff;
}

@media (max-width: 768px) {
    .main-logo {
        display: none;
    }

    .mini-main-logo {
        height: 45px;
        display: block;
    }

    .top-bar {
        flex-direction: row;
        justify-content: space-between;
    }

    .select-group {
        display: flex;
        gap: 10px;
    }
}