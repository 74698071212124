.add-to-cart-container {
    padding: 20px;
    max-width: 350px;
    margin: 20px auto;
    text-align: center;
}

.quantity-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

@media (max-width: 768px) {
    .add-to-cart-container {
        max-width: 100%;
        padding: 15px;
    }
    
    .buttons-container {
        flex-direction: column;
        align-items: center;
    }
}
