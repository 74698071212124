.product-list {
    max-width: 1100px;
    margin: 10px auto;
    padding: 0 15px;
}

.product-list-header {
    padding: 0 0 20px 0;
}

.product-list .ant-card {
    text-align: center;
}

.product-list .ant-card:hover {
    transform: translateY(-5px);
}

.product-card-title {
    padding: 0;
    min-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.skeleton-xyz {
    width: 100%;
    height: 200px;
}