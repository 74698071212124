.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    color: #282c34;
}

.not-found-content {
    text-align: center;
    padding: 20px;
}

.not-found-title {
    font-size: 48px;
    margin-bottom: 10px;
    font-weight: bold;
}

.not-found-message {
    font-size: 20px;
    margin-bottom: 20px;
}

.not-found-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.not-found-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #61dafb;
    color: #282c34;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.not-found-button:hover {
    background-color: #4db3e1;
}