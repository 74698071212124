/* Contenedor principal que ocupa toda la ventana */
.main-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #000; /* Fondo de respaldo */
}

/* Tarjeta de banner principal */
.main-banner-card {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: transparent;
}

/* Imagen de fondo que cubre toda el área */
.main-banner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    z-index: 0;
}

/* Contenido centrado */
.main-banner-content {
    position: relative;
    z-index: 1;
    color: #fff;
    text-align: center;
    max-width: 800px;
    padding: 20px;
}

/* Título y párrafo */
.main-banner-content h1 {
    color: #fff;
    font-size: 2.5em;
    margin: 0;
}

.main-banner-content p {
    color: #fff;
    font-size: 1.2em;
    margin-top: 0.5em;
}

/* Botón */
.main-banner-content .ant-btn-primary {
    margin-top: 1em;
    font-size: 1.1em;
    padding: 0.8em 1.5em;
}