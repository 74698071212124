
.breadcrumb-modern {
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.breadcrumb-list-modern {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.breadcrumb-item-modern {
  display: flex;
  align-items: center;
  margin-right: 6px;
  transition: all 0.3s ease;
}

.breadcrumb-link-modern {
  text-decoration: none;
  color: var(--theme-breadcrumb-color);
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 15px;
  background-color: #eef3fb;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.breadcrumb-link-modern:hover {
  background-color: var(--theme-breadcrumb-color);
  color: #fff;
}

.breadcrumb-separator-modern {
  color: #888;
  margin-left: 3px;
  margin-right: 3px;
}

@media (max-width: 768px) {
  .breadcrumb-modern {
      font-size: 10px;
      padding: 4px;
  }

  .breadcrumb-item-modern {
      margin-right: 3px;
  }

  .breadcrumb-list-modern {
      justify-content: center;
  }

  .breadcrumb-item-modern {
      margin-bottom: 3px;
  }
}