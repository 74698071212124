.category-list {
    max-width: 1100px;
    margin: 0 auto;
    padding: 16px;
}

.category-list .ant-card {
    border-radius: 8px;
    transition: transform 0.3s;
    text-align: center;
}

.category-list .ant-card:hover {
    transform: translateY(-5px);
}

.category-list .ant-card img {
    object-fit: cover;
}