.footer-aws {
  background-color: var(--theme-background-clear-color);
  color: #ffffff;
  padding: 15px 20px;
  display: grid;
  gap: 20px;
}

@media (min-width: 1024px) {
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    gap: 20px;
  }

  .footer-links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 20px;
  }

  .footer-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .footer-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    gap: 20px;
  }

  .footer-links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.footer-subscribe {
  text-align: right;
}

.footer-input {
  border-radius: 50px;
  margin-right: 10px;
  width: 100%;
}

.footer-button {
  background: linear-gradient(90deg, rgba(138, 51, 177, 1) 0%, rgba(249, 113, 0, 1) 100%);
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.footer-button:hover {
  opacity: 0.8;
}

.footer-nav-list {
  list-style: none;
  padding: 0;
}

.footer-nav-list li {
  margin: 5px 0;
}

.footer-social-list,
.footer-payment-list {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
}

.footer-social-list li {
  margin: 0 9px;
}

.footer-payment-list li {
  margin-right: 0;
}

.footer-social-link,
.footer-payment-link {
  color: #ffffff;
  font-size: 1.5em;
  transition: color 0.3s ease;
}

.footer-social-link:hover{
  color: #fa6f00;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}