.ecommerce-menu {
    background-color: var(--theme-background-clear-color);
    padding: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.ecommerce-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-flex;
}

.ecommerce-menu ul li {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    font-weight: bold;
    font-size: 13px;
}

.ecommerce-menu ul li a {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;
}

.ecommerce-menu ul li span,
.ecommerce-menu .hamburger-icon {
    color: #ffffff;
    text-decoration: none;
    padding-bottom: 5px;
    transition: all 0.5s ease;
    font-size: 13px;
}

.ecommerce-menu ul li:last-child {
    border-right: none;
}

.ecommerce-menu ul li:hover {
    background-color: var(--theme-second-color);
}

.ecommerce-menu .hamburger-icon:hover {
    background-color: inherit;
}

.ecommerce-menu ul li ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--theme-background-clear-color);
    padding: 10px 0;
    min-width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    text-align: center;
}

.ecommerce-menu ul li:hover ul {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 1;
}

@media (max-width: 768px) {
    .ecommerce-menu ul {
        flex-direction: column;
    }

    .ecommerce-menu ul li {
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .ecommerce-menu ul li:last-child {
        border-bottom: none;
    }
}